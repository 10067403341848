import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_towalkagain',
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.informations')
      },
      components: [
        {
          path: 'modules/pages/SectionContainer',
          model: 'logo',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'image',
              settings: {
                hide_label: true,
                crop: false,
                size: { width: 9999, height: 162 }
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.navigation')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'navigation',
          content: {
            wrapper_title: 'link.text',
            default_wrapper_title: i18n.getLocalesTranslations('literal.link'),
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          settings: {
            layout: 'inline',
            hide_label: true
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.language_switch')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.contact_us')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'email',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.contact'),
            wrapper_title: 'title'
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'title',
              content: {
                label: i18n.getLocalesTranslations('literal.title')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.email')
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'address',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.address'),
            wrapper_title: 'title'
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'title',
              content: {
                label: i18n.getLocalesTranslations('literal.title')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.address')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.actions')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'cta',
          content: {
            wrapper_title: 'link.text',
            default_wrapper_title: i18n.getLocalesTranslations('literal.call_to_action'),
            btn_label: i18n.getLocalesTranslations('literal.add_new_button')
          },
          settings: {
            layout: 'inline',
            hide_label: true
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    }
  ]
}
