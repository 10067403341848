import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_towalkagain',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'topbar',
      content: {
        default_wrapper_title: {
          'fr-FR': 'Barre supérieure',
          'en-GB': 'Top bar',
          'nl-NL': 'Bovenbalk'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'email',
          content: {
            label: i18n.getLocalesTranslations('literal.email')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'cta',
          settings: {
            layout: 'inline'
          },
          content: {
            label: i18n.getLocalesTranslations('literal.call_to_action'),
            btn_label: i18n.getLocalesTranslations('literal.add_new_button')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              },
              content: {
                label: i18n.getLocalesTranslations('literal.button')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 9999, height: 80 }
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'link.text',
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'link',
          settings: {
            options: [
              { value: 'link', label: i18n.getLocalesTranslations('literal.link') },
              { value: 'button', label: i18n.getLocalesTranslations('literal.button') }
            ]
          },
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'button',
          settings: {
            hide_label: true
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'button',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'link',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'sub_links',
          settings: {
            hide_label: true,
            layout: 'inline',
          },
          content: {
            wrapper_title: 'link.text',
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'sub_link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.language_switch')
      }
    }
  ]
}
